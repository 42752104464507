.departures-container {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.departures-container .departures-header {
  width: 100%;
  height: 22%;
  background-color: #020c41;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.departures-header img {
  width: 300px;
}

.departures-container .info-content {
  width: 100%;
  height: 38px;
  background: #030d75;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5%;
  position: relative;
}

.departures-container .info-content p {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.return-content {
  position: absolute;
  width: 80px;
  height: 60px;
  background: #020c41;
  bottom: -90px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  cursor: pointer;
}

.departures-container .departures-body {
  display: flex;
  flex-direction: column;
  padding: 20px 10%;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.table-format {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 15px;
}

.table-format th {
  background-color: #020c41;
  font-weight: bold;
  color: #fff;
  padding: 15px 10px;
}

.table-format tbody tr td {
  padding: 0 10px;
  height: 60px;
}

.table-format tbody tr td.borderStyle {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-left: none;
  border-right: none;
}

.table-format tbody tr td.borderStyle:first-child {
  border-left: 1px solid #000;
}

.table-format tbody tr td.borderStyle:nth-child(4) {
  border-right: 1px solid #000;
}

.table-format tbody tr .search-text {
  width: 180px;
  margin: auto;
  border-radius: 5px;
  padding: 5px;
  font-size: 1rem;
  color: #fff;
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.table-format .actionTd {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.table-format .actionTd .box-container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  position: relative;
}

.dep-tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #e5e5e5;
  color: #000;
  border-radius: 5px;
  padding: 3px 5px;
  position: absolute;
  z-index: 1;
  left: 53px;
  font-size: .8rem;
  white-space: nowrap;
  transition: opacity 0.3s, visibility 0.3s;
  -webkit-box-shadow: -3px 3px 10px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: -3px 3px 10px -3px rgba(0,0,0,0.75);
  box-shadow: -3px 3px 10px -3px rgba(0,0,0,0.75);
}

.box-container:hover .dep-tooltip {
  visibility: visible;
  opacity: 1;
}

.dep-tooltip:hover {
  display: none;
}

/* MODAL */
.modal-container .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 668px;
  width: 100%;
}

.modal-container .modal-body {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.modal-container .close-modal-btn {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-container .close-modal-btn span {
  width: 30px;
  height: 30px;
  font-size: 24px;
  background: #020c41;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.modal-container .modal-icon {
  font-size: 50px;
  color: #001f5b;
  margin-bottom: 10px;
}

.modal-container .modal-text {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 20px;
  text-align: center;
}

.modal-container .custom-message {
  font-size: 20px;
  color: #035cf7;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.modal-container .modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.modal-container .modal-buttons button {
  width: 100px;
}

.modal-container .btn-close-dep {
  width: 90%;
  background: #035cf7;
  margin-top: 40px;
}

.dep-bg-gray {
  background: #737373;
  transition: .3s ease all;
  position: relative;
}
.dep-bg-gray:hover {
  background: #020c41;
}
.dep-bg-blue {
  background: #035cf7;
  transition: .3s ease all;
  position: relative;
}
.dep-bg-blue:hover {
  background: #0000e3;
}
.dep-bg-red {
  background: #ed544e;
  transition: .3s ease all;
  position: relative;
}
.dep-bg-red:hover {
  background: #8c0005;
}
.table-format .bg-green {
  background: #66c87b;
}
.dep-bg-green {
  background: #66c87b;
  transition: .3s ease all;
  position: relative;
}
.dep-bg-green:hover {
  background: #00c82b;
}
.table-format .bg-orange {
  background: #f27d38;
}
.dep-bg-orange {
  background: #bd9a00;
  transition: .3s ease all;
  position: relative;
}
.dep-bg-orange:hover {
  background: #967b02;
}