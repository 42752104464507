.amc-domestic-header {
  width: 100%;
  height: 22%;
}

.amc-domestic-header .amc-domestic-header-img {
  background-color: #020c41;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 75%;
}

.amc-domestic-header-img .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 75%;
}

.amc-domestic-header-img .logo img {
  width: 300px;
  height: 300px;
}

.amc-domestic-header-img .lines_pink {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: end;
  width: 25%;
}

.amc-domestic-header-img .lines_pink img {
  width: 45%;
  height: 100%;
  margin-right: -65px;
}

.amc-domestic-header .amc-domestic-header-hours {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 15px;
}

.amc-domestic-header-hours .hours {
  background-color: #030d75;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  width: 70%;
  height: 100%;
}

.amc-domestic-header-hours .user {
  background-color: #0000e3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 30%;
  height: 100%;
}

.user .username {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dropdownMenuHeader {
  position: absolute;
  top: 198px;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: 460px;
}

.dropdownMenuHeader ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdownMenuHeader ul li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  background-color: #123660;
}

.dropdownMenuHeader li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.dropdownMenuHeader .exit-btn {
  background-color: #ed544e;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  height: 40px;
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .amc-domestic-header-img .logo {
    justify-content: flex-start;
    width: 68%;
  }

  .dropdownMenuHeader {
    top: 225px !important;
    width: 231px !important;
  }
}

/* ============================================================
  PHONE
  ============================================================ */
@media screen and (max-width: 600px) {
  .amc-domestic-header {
    height: 18%;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-title {
    height: 32%;
  }

  .amc-domestic-header .amc-domestic-header-img {
    height: 70%;
  }

  .amc-domestic-header-img .logo {
    width: 100%;
  }

  .amc-domestic-header-img .lines_pink {
    display: none;
  }

  .amc-domestic-header .amc-domestic-header-hours {
    width: 100%;
    height: 30%;
    flex-direction: column;
  }

  .amc-domestic-header-hours .hours {
    width: 100%;
    height: 50%;
  }

  .amc-domestic-header-hours .user {
    width: 100%;
    height: 50%;
  }

  .dropdownMenuHeader {
    top: 186px;
    width: 389px;
  }

  .amc-home-content
    .amc-domestico-home-content-content
    .amc-domestico-home-content-content-form {
    height: 70%;
  }
}
