.service-container {
  padding: 20px;
}

/********************** National **********************/
.card {
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0% !important;
  cursor: pointer;

  position: relative;
  background-color: white;
  transition: background-color 0.3s ease;
  overflow: hidden; /* Para evitar que el contenido sobrepase los límites de la tarjeta */
  height: 900px; /* Fija una altura para la tarjeta */
}

.card:hover {
  background-color: #E6E6E3;
}

.card-image {
  width: 100%;
  height: 145px; /* Ajusta la altura deseada */
  object-fit: cover; /* Recorta la imagen en lugar de deformarla */
  overflow: hidden;
  transition: opacity 0.3s ease;
}

.card:hover .card-image {
  opacity: 0; /* Ocultar la imagen al hacer hover */
}

.card-content {
  gap: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Mantiene los íconos y título alineados a la izquierda */

  position: relative;
  top: 0;
  transition: transform 0.3s ease;
}

.card:hover .card-content {
  transform: translateY(-170px); /* Desplaza el contenido hacia arriba */
}

.card-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-premium-blue) !important;
}

.card-icon {
  font-size: 1.5rem;
  color: var(--color-premium-blue);
}

.arrow-icon {
  margin-left: auto;
  font-size: 1.5rem;
}

.description-landing {
  display: none;
  font-size: 14px;
  color: var(--color-premium-blue);
  transition: opacity 0.3s ease;

  position: absolute; /* Posiciona la descripción de forma absoluta */
  top: 100px; /* Ajusta esta posición para que quede debajo del título */
  padding-top: 20px; /* Espaciado entre el título y la descripción */
}

.card:hover .description-landing {
  display: block; /* Muestra la descripción al hacer hover */
  opacity: 1;
  transition: opacity 0.3s ease;
}
/********************** National **********************/

/********************** International **********************/
.card-international {
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0% !important;
  cursor: pointer;

  position: relative;
  background-color: white;
  transition: background-color 0.3s ease;
  overflow: hidden; /* Para evitar que el contenido sobrepase los límites de la tarjeta */
  height: 300px; /* Fija una altura para la tarjeta */
}

.card-international:hover {
  background-color: #E6E6E3;
}

.card-international-image{
  display: block;
  width: 80%;
  position: relative;
  top: 80px; /* Ajusta la posición vertical */
  transition: transform 0.3s ease; /* Para la animación en el hover */
}

.card-international:hover .card-international-image {
  transform: translateY(-80px); /* Mueve la imagen hacia arriba al hacer hover */
}

.card-international-content {
  gap: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Mantiene los íconos y título alineados a la izquierda */

  opacity: 0;
  transform: translateY(20px); /* Desplaza el contenido hacia abajo */
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.card-international:hover .card-international-content {
  opacity: 1; /* Muestra el contenido en hover */
  transform: translateY(0); /* Desplaza el contenido hacia arriba */
}

.card-international-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-premium-blue) !important;
}

.description-international {
  font-size: 14px;
  color: var(--color-premium-blue);
  position: absolute;
  top: 50px;
  padding-top: 20px; /* Espaciado entre el título y la descripción */
}

.arrow-international-icon {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
/********************** International **********************/

/********************** Additional **********************/
.card-additional {
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0% !important;

  position: relative;
  background-color: white;
  transition: background-color 0.3s ease;
  overflow: hidden; /* Para evitar que el contenido sobrepase los límites de la tarjeta */
  height: 350px; /* Fija una altura para la tarjeta */
}

.card-additional:hover {
  background-color: #E6E6E3;
}

.card-additional-content {
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Mantiene los íconos y título alineados a la izquierda */
  position: relative;
  top: 0;
  transition: transform 0.3s ease;
}

.card-additional-title {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-premium-blue) !important;
}

.description-additional {
  font-size: 15px;
  color: var(--color-premium-blue);

  padding-top: 5px; /* Espaciado opcional entre el título y la descripción */
}
/********************** Additional **********************/

/**********************Modal for services**********************/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay .modal-content {
  width: 1300px;
  height: 700px;
  background-color: white;
  
  position: relative;
  overflow: hidden;
}

.modal-scrollable-content {
  height: 100%;
  overflow-y: auto;
}

.modal-close-button{
  padding: 0px;
  border: none;
  background: transparent;
}

.modal-close-button .close-icon {
  position: absolute;
  top: 15px;
  right: 30px;
}

.modal-image {
  width: 100%;
  height: 145px; /* Ajusta la altura deseada */
  object-fit: cover; /* Recorta la imagen en lugar de deformarla */
  overflow: hidden;
}

.modal-text-content {
  padding: 50px; /* Margen entre la imagen y el contenido de texto */
  color: var(--color-azul-consistencia);
}

.modal-text-content h2{
  font-size: 28px;
}

.modal-text-content p {
  margin-top: 30px;
  color: black;
  font-size: 15px;
  line-height: 34px;
  margin-bottom: 30px;
}

.modal-text-content ul li {
  color: black;
  font-size: 15px;
  line-height: 34px;
}

/**Barra de scroll**/
.modal-scrollable-content::-webkit-scrollbar {
  width: 12px; /* Ancho de la barra de scroll */
}

.modal-scrollable-content::-webkit-scrollbar-track {
  background: #e0e0e0; /* Color de fondo del track */
}

.modal-scrollable-content::-webkit-scrollbar-thumb {
  background-color: var(--color-azul-consistencia);; /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Bordes redondeados en la barra */
  border: 3px solid #e0e0e0; /* Borde alrededor de la barra */
}

.modal-scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #0055cc; /* Color de la barra al hacer hover */
}

/* Barra de desplazamiento en Firefox */
.modal-scrollable-content {
  
  scrollbar-color: var(--color-azul-consistencia) #e0e0e0; /* Color de la barra y el track */
}

/* Estilos de las flechas en WebKit */
.modal-scrollable-content::-webkit-scrollbar-button {
  background-color: #003366;
  height: 20px;
}

.modal-scrollable-content::-webkit-scrollbar-button:hover {
  background-color: #0055cc; /* Color de las flechas al hacer hover */
}

.custom-accordion .accordion-item {
  background-color: var(--color-azul-consistencia);
  border: none;
  margin-bottom: 15px;
}

.custom-accordion .accordion-body {
  color: white !important;
}

.custom-accordion .accordion-header .accordion-button {
  background-color: var(--color-azul-consistencia);
  color: white;
  font-weight: bold;
  padding: 25px;
  border: none;
  box-shadow: none;
}

.custom-accordion .accordion-header .accordion-button::after {
  color: white;
  filter: brightness(0) invert(1);
}

.custom-accordion .accordion-body {
  background-color: var(--color-azul-consistencia);
  color: white;
  padding: 15px;
}
/********************** Modal for services **********************/