.contact-container {
    padding: 140px 100px;
    color: #fff;
}

.icon-copy {
    width: 30px;
}

.icon-whatsapp {
    font-size: 25px; /* Ajusta el tamaño según lo que necesites */
    padding-bottom: 3px;
  }

.btn-pink {
    border-radius: 6px;
    background-color: var(--color-azul-innovacion);
    border: none;
    padding: 10px 15px;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    transition: .3s ease all;
    pointer-events: auto; /* Reactiva los eventos para el botón */
}

.btn-pink:hover {
    background: var(--color-mexican-pink);
    color: #fff;  
}

.h6-responsive {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 768px) {
    .contact-container {
        padding: 50px 20px;
    }

    .h6-responsive {
        word-break: break-word;
    }
}

@media (max-width: 320px) {
    .contact-container h6 {
        font-size: 0.7rem; /* Ajusta el tamaño del texto para pantallas pequeñas */
      }
}

