.shifts-container {
  width: 100%;
  height: auto;
}

.nav-tabs-container {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #020c41;
  display: flex;
  margin-bottom: 20px;
}

.nav-tabs-container .btn-tab {
  background: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  transition: .3s ease all;
}

.nav-tabs-container .btn-tab:hover {
  background: #f0f0f0;
  border: none;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  transition: .3s ease all;
}

.nav-tabs-container .btn-tab.active {
  background: #020c41;
  border: none;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  color: #fff;
}