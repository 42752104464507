.coverage-container {
  width: 100%;
  display: flex;
  margin-top: 20px;
}

.coverage-container .gray-section {
  width: 40%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.coverage-container .white-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  width: 60%;
  position: relative;
}

.gray-section .dest-covera-section {
  width: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.gray-section .dest-covera-section h4 {
  font-size: 2rem;
  color: #000;
}

.gray-section .dest-covera-section .buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.gray-section .buttons button {
  width: 100%;
  height: auto;
  padding: 15px;
  background: #123660;
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: .5s ease all;
}

.gray-section .buttons button.static {
  width: 100%;
  height: auto;
  padding: 15px;
  background: var(--color-premium-blue);
  color: #ffffff;
  border: none;
  font-weight: bold;
  transition: .5s ease all;
  cursor: auto;
}

.gray-section .buttons button:hover {
  background: var(--color-premium-blue);
}

.gray-section .buttons button.static {
  background: var(--color-premium-blue);
}

.coverage-container .white-section .station-national-maps {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  width: 120%;
  height: auto;
  min-height: 500px;
  padding: 0;
  transition: .3s ease all;
}

.station-national-maps .active-color {
  fill: #123660;
  transition: .3s ease all;
}

.station-national-maps .hidden {
  opacity: 1;
}

.gray-section .btn-return {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-premium-blue);
  top: 10px;
  left: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: .3s ease all;
  font-size: 1.2rem;
  border: none;
}

.gray-section .btn-return:hover {
  background: #fff;
  color: var(--color-premium-blue);
}

.coverage-container .show-animation {
  opacity: 1;
  transition: .3s ease all;
}

.coverage-container .hidden-animation {
  opacity: 0;
  transition: .3s ease all;
}

.white-section .show-stations.show {
  width: 100%;
  height: 100%;
  background: var(--color-premium-blue);
  position: absolute;
  z-index: 15;
  transition: .5s ease all;
  top: 0;
  left: 0;
  overflow: hidden;
}

.white-section .show-stations {
  width: 100%;
  height: 0%;
  background: var(--color-premium-blue);
  position: absolute;
  z-index: 15;
  transition: .5s ease all;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show-stations .national-details {
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 20px;
  gap: 10px;
}

.show-stations .national-details p {
  font-size: 1rem;
}

.show-stations .stations-container {
  padding: 25px 20px;
  color: #fff;
  font-weight: bold;
  width: 100%;
  height: auto;
  max-height: 500px;
  column-width: 250px;
  overflow-y: auto;
}

.stations-container ul {
  width: 100%;
  height: 100%;
}

.stations-container li {
  break-inside: avoid;
  margin: 5px 0;
  display: flex;
}

.stations-container li p {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.international-stations-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  color: #fff;
  padding: 20px;
}

.international-stations-container .item-section {
  text-align: start;
  width: auto;
}

.international-stations-container .item-section ul {
  margin-top: 5px;
  padding-left: 15px;
  font-size: .9rem;
}

.international-stations-container .item-section ul a {
  color: #fff;
}

.international-stations-container .item-section .country-text {
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-size: 1.1rem;
  width: auto;
}

/* ============================================================
TABLETS
============================================================ */
@media screen and (min-width: 766px) and (max-width: 1000px) {
  .gray-section .dest-covera-section {
    gap: 15px;
  }

  .gray-section .dest-covera-section h4 {
    font-size: 1.5rem;
    color: #000;
    margin-top: 25px;
  }

  .gray-section .dest-covera-section .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 1rem;
  }
  .coverage-container .white-section .station-national-maps {
    min-height: 300px;
  }

  .show-stations .stations-container {
    padding: 15px 10px;
    max-height: 320px;
    column-width: 180px;
  }

  .international-stations-container {
    gap: 15px;
    padding: 10px 20px;
    justify-content:space-around;
    width: 100%;
    height: auto;
    max-height: 300px;
    overflow: auto;
  }
}

/* ============================================================
PHONE
============================================================ */
@media screen and (max-width: 765px) {
  .coverage-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .coverage-container .gray-section {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
  }
  
  .coverage-container .white-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: coral;
  }
  .coverage-container .white-section .station-national-maps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    width: 120%;
    height: auto;
    min-height: 400px;
    padding: 0;
    transition: .3s ease all;
  }
  .show-stations .stations-container {
    padding: 15px 10px;
    max-height: 400px;
    column-width: 180px;
  }
  .international-stations-container {
    gap: 15px;
    padding: 10px 20px;
    justify-content:space-around;
    width: 100%;
    height: auto;
    max-height: 400px;
    overflow: auto;
  }
}

@media screen and (max-width: 485px) {
  .gray-section .dest-covera-section h4 {
    font-size: 1.5rem;
    color: #000;
  }
  .show-stations .stations-container {
    padding: 15px 10px;
    max-height: 260px;
    column-width: 180px;
  }
  .international-stations-container {
    gap: 15px;
    padding: 10px 20px;
    justify-content:space-around;
    width: 100%;
    height: auto;
    max-height: 260px;
    overflow: auto;
  }
  .coverage-container .white-section .station-national-maps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    width: 120%;
    height: auto;
    min-height: 260px;
    padding: 0;
    transition: .3s ease all;
  }
  .show-stations .national-details p {
    font-size: .8rem;
  }
}