.amc-weigher-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background-color: #ffffff;
}

.amc-weigher-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
}

.amc-weigher-content .amc-domestico-weigher-content-content {
  width: 100%;
  height: 80%;
}

.amc-weigher-content
  .amc-domestico-weigher-content-content
  .amc-domestico-weigher-content-content-title {
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weiguer-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.weiguer-title button {
  border: none;
  padding: 10px 15px;
  border-radius: 1px;
  background: #001244;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 10px;
}

.amc-domestico-weigher-content-content-body {
  height: 70%;
  width: 57%;
  margin-left: 332px;
}

.amc-domestico-weigher-content-content-body-turn {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 4px;
  padding: 2px;
  height: 100%;
}

/* Cards */
.turn-card {
  background-color: #001244;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.turn-card .text-bold {
  font-weight: bold;
}

.turn-card:hover {
  background-color: rgba(0, 18, 68, 0.8);
}

.turn-card h1 {
  font-size: 25px;
  color: #ffffff;
}

.turn-card h3 {
  font-size: 20px;
}

.turn-card p {
  font-size: 16px;
}

/* Modal */
.custom-modal-width {
  max-width: 900px !important;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.content-btns-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 60px;
  overflow: hidden;
  transition: .3s ease all;
}

.content-btns-input.active {
  height: 290px;
}

.content-btns-input .cancel-text {
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.btns-decision {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
}

.btns-decision .cancel-btn {
  width: 50%;
  background: #ed544e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  gap: 20px;
}

.btns-decision .acept-btn {
  width: 50%;
  background: #3d784a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: none;
  height: 50px;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  gap: 20px;
}

.content-btns-input textarea {
  border: 1px solid #d4d4d4;
  width: 99%;
  min-width: 99%;
  max-width: 99%;
  min-height: 150px;
  max-height: 150px;
  margin-left: 2px;
}

@media screen and (min-width: 1920px) {
  .amc-domestico-weigher-content-content-body {
    height: 70%;
    width: 77%;
    margin-left: 250px;
  }

  .turn-card {
    padding: 40px;
    font-size: 30px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
  .amc-domestico-weigher-content-content-body {
    height: 70%;
    width: 77%;
    margin-left: 172px;
  }

  .turn-card {
    font-size: 20px;
  }
}

@media screen and (min-width: 913px) and (max-width: 1279px) {
  .amc-weigher-content .amc-domestico-weigher-content-content {
    width: 100%;
  }

  .amc-domestico-weigher-content-content-body {
    height: 70%;
    width: 67%;
    margin-left: 175px;
  }
}

/* ============================================================
    TABLETS
    ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .amc-weigher-content .amc-domestico-weigher-content-content {
    width: 100%;
    height: 100%;
  }

  .amc-domestico-weigher-content-content-body {
    height: 70%;
    width: 70%;
    margin-left: 130px;
  }
}

/* ============================================================
        PHONE
        ============================================================ */
@media screen and (max-width: 620px) {
  .amc-weigher-container {
    width: 100%;
    height: 846px;
  }

  .amc-weigher-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
  }

  .amc-weigher-content
    .amc-domestico-weigher-content-content
    .amc-domestico-weigher-content-content-title {
    height: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amc-weigher-content .amc-domestico-weigher-content-content {
    width: 100%;
    height: 100%;
  }

  .amc-domestico-weigher-content-content-body {
    height: 85%;
    width: 96%;
    margin-left: 6px;
    overflow-x: scroll;
  }

  .amc-domestico-weigher-content-content-body-turn {
    grid-template-columns: repeat(3, 1fr);
  }
}
