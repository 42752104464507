.seeker-container {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.seeker-container .seeker-header {
  width: 100%;
  height: 22%;
  background-color: #020c41;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../../../assets/imgs/amc-domestico/Estelas_AMC.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

.seeker-header img {
  width: 300px;
}

.seeker-container .info-content {
  width: 100%;
  height: 38px;
  background: #030d75;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 5%;
  position: relative;
}

.seeker-container .info-content p {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.seeker-container .seeker-body {
  display: flex;
  flex-direction: column;
  padding: 20px 10%;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
}

.seeker-body .turns-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 40px;
}

.seeker-body .turns-content .wait-turn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #035cf7;
  width: auto;
  padding: 0 10px;
  height: 50px;
  border-radius: 5px;
  color: #fff;
}

.seeker-body .wait-turn p {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.seeker-body .wait-turn .turn-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #737373;
  border-radius: 5px;
  font-weight: bold;
}

.seeker-form {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.input-seeker {
  margin: 0px;
}

.seeker-form button {
  width: auto;
  height: 50px;
  border: 1px solid #035cf7;
  border-radius: 5px;
  background: #035cf7;
  color: #fff;
  padding: 0 20px;
}

.table-format {
  position: relative;
}

.not-turn-register-text {
  width: 100%;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 250px;
}

.select-seeker {
  width: auto;
  height: 35px;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0 10px;
}

.borderLastStyle {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
}

.actionTdSeek {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.table-format .actionTdSeek .box-container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.box-container-none {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
}

.modal-text-seek {
  font-size: 1.2rem;
  text-align: center;
  height: auto;
  min-height: 60px;
}

.guide-number {
  margin: 0;
  padding: 0;
}

.guide-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn-confirm-seek {
  margin-top: 20px;
  width: 100%;
}

.setjustification-form {
  width: 100%;
}

.seek-bg-blue {
  background: #035cf7;
}
.seek-bg-green {
  background: #66c87b;
}
.seek-bg-orange {
  background: #f27d39;
}
.seek-bg-red {
  background: #ed544e;
}
.seek-bg-white {
  background: #fff;
}
.seek-bg-gray {
  background: #737373;
}
.seek-bg-yellow {
  background: #f4c700;
}
.seek-color-blue {
  color: #035cf7;
  font-weight: bold;
}
.seek-color-blue-bold {
  color: #035cf7;
  font-weight: bold;
  font-size: 1.2rem;
  height: 23px;
}
