.folderButton {
    font-size: 23px;
    font-weight: 600;
    margin-left: 10px;
    color: #0e2240;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
}

.folderButton:hover {
    transform: scale(1.2);
}