.container-turns {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-color: #ffffff;
}

.turns-body {
  width: 100%;
  height: 78%;
  min-height: 78%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-turns-table {
  width: 95%;
  height: 90%;
  min-height: 90%;
}

.container-turns-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
}

.container-turns-table table thead {
  background-color: var(--color-premium-blue);
  color: white;
}

.container-turns-table table thead th {
  padding: 0.75rem;
  text-align: center;
  font-weight: bold;
}

.container-turns-table table tbody td {
  padding: 0.75rem;
  border: 1px solid black;
  text-align: center;
}

.container-turns-table table tbody td:nth-child(2) {
  background: var(--color-premium-blue);
  color: #fff;
}

.container-turns-table table tbody .butons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.container-turns-table table tbody button {
  border-radius: 5px;
  width: 100px;
  height: 30px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  border: none;
}

.container-turns-table table .butons-container .release {
  background-color: #f27d38;
}

.container-turns-table table .butons-container .finish {
  background-color: #045cf7;
}

.finsh-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
  gap: 0.5rem;
}

.finsh-container button {
  padding: 0.4rem 0.8rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 4px;
}

.finsh-container button:hover {
  background-color: #e0e0e0;
}

.finsh-container button:disabled {
  background-color: #ddd;
  color: #000000;
  cursor: not-allowed;
}

.finsh-container button.active {
  background-color: var(--color-premium-blue);
  color: white;
  font-weight: 600;
  font-style: normal;
  font-style: normal;
  line-height: normal;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
}

/* ============================================================
      PHONE
      ============================================================ */
@media screen and (max-width: 620px) {
  .turns-body {
    width: 100%;
    height: 82%;
    min-height: 82%;
  }

  .container-turns-table {
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }

  .finsh-container {
    justify-content: center;
    margin-top: 0.3rem;
    margin-right: 0rem;
    gap: 0.5rem;
  }
}
