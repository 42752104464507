.clients-collection-container {
  width: 100%;
  height: 100dvh;
}

.clients-collection-body {
  width: 100%;
  height: 78%;
}

.return-container {
  width: 5%;
  height: 8%;
  position: absolute;
  top: 15;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.return-btn {
  width: 70%;
  height: 60%;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  background-color: #020b41;
}

.arrow-btn {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}

/* Perfiles */
.collection-profile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collection-profile .profiles {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.collection-profile .profiles .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.collection-profile .profiles .profile-content {
  display: flex;
  flex-direction: row;
  gap: 10%;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 35%;
}

.profile-content .profile {
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile .circle {
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #123660;
  width: 80%;
  height: 90%;
}

/* Form */
.form-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.form-body form {
  all: unset;
  display: contents;
}

.form-container {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.form-container .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-align: center;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 600px;
  height: 70%;
}

/* inputs */
.form-container .form .formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 10px 0 10px 0;
  width: 100%;
}

.form-container .form .formGroup input {
  background: #ffffff;
  font-size: 0.9rem;
  border: none;
  outline: none;
  border-radius: 0px;
  border: 2px solid #d4d4d4;
  transition: 0.3s ease all;
}

.form-container .form .formGroup input {
  width: 80%;
  height: 45px;
  min-height: 45px;
  padding: 10px 10px 1px 10px;
}

.form-container .form .formGroup input:disabled + label {
  opacity: 0.3;
  cursor: auto;
}

.form-container .form .formGroup input:focus {
  border: 2px solid #3405e1;
}

.form-container .form .formGroup input:focus + label,
.form-container .form .formGroup input:not(:placeholder-shown) + label {
  transform: translateY(1px) scale(0.6);
  transform-origin: left top;
  color: #323a8f;
}

.form-container .form .formGroup input:focus {
  padding: 10px 10px 1px 10px;
}

.form-container .form .formGroup label {
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 69px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 1rem;
  margin-bottom: 4px;
  color: #000;
  font-weight: bolder;
}

.button-container {
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: #e5e5e5;
}

.btns {
  width: 80%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

.btns .save {
  width: 90%;
  height: 80%;
  background-color: #045cf7;
  color: #ffffff;
  border: none;
  border-radius: 5px;
}

/* Modal */
.custom-modal .modal-dialog,
.custom-modal-ticket .modal-dialog,
.custom-modal-confirm .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 668px;
  width: 100%;
}

.custom-modal-ticket .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-width: 450px;
  width: 100%;
}

.custom-modal .modal-header {
  background-color: transparent;
  border: none;
  position: relative;
}

.custom-modal p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 900;
  color: #5f5353;
}

.custom-modal .custom-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #7ba9f7;
  border-top-color: #0b3f6a;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.custom-modal .modal-header button,
.custom-modal-ticket .modal-header button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 10;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.custom-modal .modal-header button:hover,
.custom-modal-ticket .modal-header button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.custom-modal-confirm .modal-icon {
  font-size: 50px;
  color: #001f5b;
  margin-bottom: 10px;
}

.custom-modal-confirm .modal-text {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.custom-modal-confirm .modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 10px;
}

.custom-modal-confirm .confirm-button {
  background-color: #035cf7;
  border: none;
  color: white;
  border-radius: 5px;
  width: 180px;
}

.custom-modal-confirm .cancel-button {
  background-color: #a3a3a3;
  border: none;
  color: white;
  border-radius: 5px;
  width: 180px;
}

.custom-modal-confirm .cancel-button:hover {
  background-color: #7a7a7a;
  color: white;
  border-color: none;
}

/* Ticket */
.amc-collection-ticket {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.amc-collection-ticket .amc-collection-ticket-header-icon img {
  width: 200px;
  height: 200px;
  margin-left: 119px;
}

.amc-collection-ticket-header-hour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
  width: 80%;
}

.amc-collection-ticket-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.amc-collection-ticket-body p {
  font-size: 18px;
}

/* Exit */
.modal-text {
  font-size: 20px;
  color: #4a4a4a;
  font-weight: 500;
  margin-bottom: 20px;
}

.exit-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
}

.exit-button:hover {
  background-color: #0056b3;
}

.hiden-turn {
  position: absolute;
  width: 1px;
  height: 1px;
  background: transparent;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media print {
  @page {
    margin: 0;
  }

  .page-break {
    page-break-after: always;
  }

  .amc-collection-ticket {
    width: 8cm;
    height: 10cm;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    font-size: 13px;
  }

  .amc-collection-ticket .amc-collection-ticket-header {
    width: 100%;
    height: 50%;
    display: flex;
    margin-top: -30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .amc-collection-ticket
    .amc-collection-ticket-header
    .amc-collection-ticket-header-icon {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .amc-collection-ticket
    .amc-collection-ticket-header
    .amc-collection-ticket-header-icon
    img {
    margin-left: 0;
  }

  .amc-collection-ticket
    .amc-collection-ticket-header
    .amc-collection-ticket-header-hour {
    width: 100%;
    height: 50%;
    margin-left: 0;
    margin-top: -30px;
  }

  .amc-collection-ticket .amc-collection-ticket-body {
    width: 100%;
    height: 50%;
  }

  .amc-collection-ticket .amc-collection-ticket-body h5 {
    font-size: 15px;
  }

  .amc-collection-ticket .amc-collection-ticket-body p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
    TABLETS
    ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .collection-profile .profiles .profile-content {
    width: 100%;
    height: 25%;
  }

  .return-container {
    width: 8%;
    height: 5%;
  }

  /* Form */
  .form-body {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .form-container {
    width: 75%;
  }

  .form-container .form {
    width: 90%;
    max-width: 700px;
    height: 80%;
  }

  .button-container {
    width: 35%;
  }
}

/* ============================================================
        PHONE
        ============================================================ */
@media screen and (max-width: 620px) {
  .clients-collection-body {
    width: 100%;
    height: 82%;
  }

  .return-container {
    width: 15%;
    height: 6%;
    top: 147px;
  }

  .collection-profile .profiles .profile-content {
    display: flex;
    flex-direction: column;
    gap: 10%;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
  }

  .profile-content .profile {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* Form */
  .form-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .form-container {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form-container .form {
    width: 100%;
    max-width: 700px;
    height: 75%;
  }

  .button-container {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #e5e5e5;
  }
}
