.search-eng-container {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
}

.create-search-eng-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  margin-bottom: 20px;
}

.form-search-update {
  width: 100%;
  height: auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-search-update .input-container-search {
  display: flex;
  gap: 15px;
  align-items: center;
}

.input-container-search label {
  width: 130px;
  display: flex;
}

.input-container-search input, .input-container-search select {
  display: flex;
  flex: 1;
}

.form-search-update .btns-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.form-search-update .btns-container button {
  padding: 7px 15px;
  border-radius: 10px;
  font-size: 1rem;
}

.form-search-update .btns-container .cancel-btn {
  background: #9d2121;
  color: #fff;
  border: none;
}

.form-search-update .btns-container .send-btn {
  background: var(--color-premium-blue);
  color: #fff;
  border: none;
}

.alert-search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
}

.alert-search p {
  margin: 0;
  padding: 0;
  font-size: .9rem;
  color: #c01d5e;
}