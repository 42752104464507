.body-reprint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.body-reprint h4 {
  font-size: 1.8rem;
  margin-bottom: 40px;
}

.body-reprint .caption {
  font-weight: bolder;
  color: #737373;
  font-size: 1rem;
}

.body-reprint .table-container {
  width: 100%;
  height: 27rem;
  max-height: 27rem;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.body-reprint table {
  width: 100%;
  max-width: 1500px;

  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
}

.body-reprint table thead {
  background-color: #a3a3a3;
  color: white;
}

.body-reprint table thead th {
  padding: 0.75rem;
  text-align: center;
  font-weight: bold;
}

.body-reprint table tbody td {
  padding: 0.75rem;
  border: 1px solid black;
  text-align: center;
}

.body-reprint table tbody td:first-child {
  background: var(--color-premium-blue);
  color: #fff;
}

.body-reprint table .status {
  display: inline-block;
  width: 167px;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  color: white;
}

.body-reprint .btns-action {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.body-reprint .btns-action button {
  border: none;
  border-radius: 5px;
  background: var(--color-premium-blue);
  color: #fff;
  padding: 6px 20px;
}