.collection-coordinator {
  width: 100%;
  height: 100vh;
}

.collection-coordinator-body {
  width: 100%;
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.return-container {
  width: 5%;
  height: 8%;
  position: absolute;
  top: 22%;
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.return-btn {
  width: 70%;
  height: 60%;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  background-color: #020b41;
}

.arrow-btn {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}

.collection-coordinator-body h4 {
  font-size: 1.8rem;
  margin-bottom: 40px;
  height: 10px;
}

.collection-coordinator-body .waiting-shifts {
  border-radius: 5px;
  width: 89%;
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.waiting-shifts .shifts {
  width: 13%;
  height: 90%;
  border-radius: 5px;
  background-color: #0000e3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.shifts .title {
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  text-align: center;
  color: #ffffff;
}

.shifts .number-of-turns {
  width: 63px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #737373;
  border-radius: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

.collection-coordinator-body .filter-container {
  height: 70px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.filter-container .inputs-container {
  width: 70%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.inputs-container .filter-input {
  width: 25%;
  height: 90%;
  padding: 4px;
}

.filter-input .formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0 10px 0;
  width: 100%;
}

.date-box {
  width: 100%;
  height: 37px;
  cursor: pointer;
  outline: none;
}

.date-box::-webkit-calendar-picker-indicator {
  filter: invert(42%) sepia(57%) saturate(4065%) hue-rotate(172deg)
    brightness(94%) contrast(97%);
  cursor: pointer;
}

.date-box::-moz-calendar-picker-indicator {
  filter: invert(42%) sepia(57%) saturate(4065%) hue-rotate(172deg)
    brightness(94%) contrast(97%);
  cursor: pointer;
}

.date-box:disabled {
  cursor: auto;
  opacity: 0.3;
}

.filter-container .buttons-container {
  width: 30%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.buttons-container .filter-button {
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-button .btn-filtrar {
  background-color: #0000e3;
  border-radius: 5px;
  border: #0000e3;
  height: 95%;
  width: 95%;
  color: #ffffff;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-align: center;
}

.filter-button .btn-delete-filtrar {
  background-color: #a3a3a3;
  border-radius: 5px;
  border: #a3a3a3;
  height: 95%;
  width: 95%;
  color: #ffffff;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  text-align: center;
}

.collection-coordinator-body .table-container {
  width: 90%;
  height: 22rem;
  max-height: 27rem;
  overflow-x: auto;
  overflow-y: auto;
  display: block;
  scrollbar-width: thin;
  white-space: nowrap;
}

.collection-coordinator-body table {
  width: max-content;
  max-width: none;
  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
}

.collection-coordinator-body table thead {
  background-color: #a3a3a3;
  color: white;
}

.collection-coordinator-body table thead th {
  padding: 0.75rem;
  text-align: center;
  font-weight: bold;
}

.collection-coordinator-body table tbody td {
  padding: 0.75rem;
  border: 1px solid black;
  text-align: center;
}

.collection-coordinator-body table tbody td:first-child {
  background: var(--color-premium-blue);
  color: #fff;
}

.collection-coordinator-body table .status {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 179px;
}

.collection-coordinator-body table .status.searching {
  background-color: #035cf7;
  color: #fff;
}

.collection-coordinator-body table .status.found {
  background-color: #66c87b;
  color: #fff;
}

.collection-coordinator-body table .status.found-partial {
  background-color: #f27d38;
  color: #fff;
}
.collection-coordinator-body table .status.not-found {
  background-color: #ed544e;
  color: #fff;
}
.collection-coordinator-body table .status.accepted {
  background-color: #66c87b;
  color: #fff;
}
.collection-coordinator-body table .status.not-accepted {
  background-color: #ed544e;
  color: #fff;
}
.collection-coordinator-body table .status.null {
  color: #000000;
}

.table-container .waiting-shifts {
  border: black solid 5px;
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
      TABLETS
      ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .return-container {
    width: 8%;
    height: 5%;
  }

  .waiting-shifts .shifts {
    gap: 41px;
  }

  .shifts .title {
    font-size: 12px;
  }

  .shifts .number-of-turns {
    width: 50px;
    height: 40px;
  }

  .collection-coordinator-body .filter-container {
    height: 80px;
    width: 89%;
  }

  .collection-coordinator-body .table-container {
    height: 32rem;
    max-height: 35rem;
  }
}

/* ============================================================
          PHONE
          ============================================================ */
@media screen and (max-width: 620px) {
  .collection-coordinator-body {
    width: 100%;
    height: 82%;
  }

  .return-container {
    width: 15%;
    height: 6%;
    top: 147px;
  }

  .collection-coordinator-body .waiting-shifts {
    width: 95%;
    height: 70px;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: auto;
  }

  .waiting-shifts .shifts {
    width: 100px;
    height: 100%;
    gap: 10px;
  }

  .shifts .title {
    height: 10px;
    font-size: 7px;
  }

  .shifts .number-of-turns {
    width: 63px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #737373;
    border-radius: 5px;
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
  }

  .collection-coordinator-body .filter-container {
    height: 120px;
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .filter-container .inputs-container {
    width: 95%;
    height: 50%;
    gap: 10px;
  }

  .filter-container .buttons-container {
    width: 70%;
    height: 50%;
    gap: 10px;
  }

  .collection-coordinator-body .table-container {
    height: 30rem;
    max-height: 33rem;
  }
}
