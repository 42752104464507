.shipping-prcs-title {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.shipping-prcs-title h3 {
  width: 100%;
  text-align: center;
  color: var(--color-premium-blue);
  font-size: 2.5rem;
}

.shipp-prcs-animation {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shipp-prcs-animation .cube {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.shipp-prcs-animation .cube .circle {
  position: absolute;
  width: 70px;
  height: 70px;
  min-width: 70px;
  min-height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d4d4d4;
  font-size: 2rem;
  color: #a3a3a3;
  z-index: 5;
  transition: .5s ease all;
}

.shipp-prcs-animation .cube .circle.active {
  background: var(--color-premium-blue);
  color: #ffffff;
  transition: .5s ease all;
}

.shipp-prcs-animation .cube .line-left {
  position: absolute;
  width: 50%;
  height: 15px;
  background: #d4d4d4;
  left: 0px;
  z-index: 2;
  transition: .5s ease all;
}

.shipp-prcs-animation .cube .line-right {
  position: absolute;
  width: 50%;
  height: 15px;
  background: #d4d4d4;
  right: 0px;
  z-index: 2;
  transition: .5s ease all;
}

.shipp-prcs-animation .cube .line-left.active, .shipp-prcs-animation .cube .line-right.active {
  background: var(--color-premium-blue);
  transition: .5s ease all;
}

.status-text {
  width: 100%;
  height: auto;
  background: coral;
  text-align: center;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.status-text p {
  position: absolute;
  opacity: 0;
  transition: .5s ease all;
}

.status-text p.active {
  opacity: 1;
  transition: .5s ease all;
  color: var(--color-premium-blue);
}