.bgAirplaneSky {
    background-image: url("../../assets/imgs/preguntas-frecuentes/bgAirplanePinkOriginal.png");
    background-size: cover;
    background-position: 0% 50%;
    background-repeat: no-repeat;
    width: 100%;
    height: 40vh;
    max-height: 75vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 2rem;
    transition: all 0.3s ease-in-out;
}

.contenido {
    color: white;
    padding: 1.5rem;
    max-width: 400px;
    /* max-width: 350px; */
    position: relative;
    left: 5%;
    z-index: 10;
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out;
}

.contenido img {
    width: 100%;
    height: auto;
}

.segundaSeccion {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

.botones-container {
    display: flex;
    gap: 20px;
}

.botonGral {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--color-mexican-pink);
    color: white;
    transition: 0.3s;
}

.botonInter {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #A3A3A3;
    color: white;
    transition: 0.3s;
}

.botonGral:hover {
    background-color: #A3A3A3;
}

.botonInter:hover {
    background-color: var(--color-mexican-pink);
}


.terceraSeccionNal {
    padding: 40px;
    display: none;
    justify-content: center;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.terceraSeccionInter {
    padding: 40px;
    display: none;
    justify-content: center;
    position: relative;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
}

.terceraSeccionNal.active {
    display: flex;
    opacity: 1;
    pointer-events: all;
    margin-top: 5px;
}

.terceraSeccionInter.active {
    display: flex;
    opacity: 1;
    pointer-events: all;
    margin-top: 5px;
}

.columnas-container {
    display: flex;
    gap: 40px;
    width: 100%;
    margin-top: 5px;
}

.columna1 {
    flex: 1;
    background: white;
    padding: 20px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    color: white;
}

.columna2 {
    flex: 1;
    background: white;
    padding: 20px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    color: white;
}

h1 {
    font-size: 20px;
    margin-bottom: 20px;
}

.dropdowns-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: fit-content;
    overflow-y: auto;
    padding-right: 10px;
}

.dropdowns-container::-webkit-scrollbar {
    width: 8px;
}

.dropdowns-container::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 4px;
}

.dropdownCat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ddd;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.dropdownPre {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-am-blue);
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.iconoRight {
    font-size: 18px;
    color: white;
}

/* Estilos para dropdowns de Categoría */
.dropdownCat {
    padding: 10px;
    cursor: pointer;
    color: white;
    text-align: left;
    transition: background-color 0.3s ease-in-out;
}

.dropdownCat.active {
    background-color: var(--color-mexican-pink) !important;
}

.dropdownCat:not(.active) {
    background-color: #737373 !important;
}

/* Estilos para dropdowns de Pregunta */
.dropdownPre {
    padding: 10px;
    cursor: pointer;
    color: white;
    text-align: left;
    transition: background-color 0.3s ease-in-out;
}

/* Primeros 4 dropdowns de Pregunta */
.dropdownPre.blue-bg {
    background-color: var(--color-am-blue);
}

  /* Últimos 2 dropdowns de Pregunta */
.dropdownPre.consistencia-bg {
    background-color: var(--color-azul-consistencia);
}

/* Contenedor desplegable de la respuesta */
.dropdown-content {
    padding: 10px;
    background-color: var(--color-am-blue);
    color: white;
    transition: max-height 0.3s ease-in-out;
}

.dropdown-content ul {
    text-align: start;
}

.dropdown-content li {
    font-size: 14px;
    color: white;
    padding: 4px 0;
}

.dropdown-content a {
    color: white;
}

.botonGral, .botonInter {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    font-weight: 700;
}

.botonGral.active, .botonInter.active {
    background-color: var(--color-mexican-pink);
    color: white;
}

.botonGral:not(.active), .botonInter:not(.active) {
    background-color: #A3A3A3;
    color: white;
}

.dropdownCat:hover {
    background-color: var(--color-mexican-pink) !important;
}

.dropdownPre:hover {
    background-color: var(--color-blue-hard);
}

.scroll-top-button {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--color-am-blue);
    padding: 10px;
    z-index: 1000;
    cursor: pointer;
    display: none;
}

@media (min-width: 3840px) {
    .bgAirplaneSky {
        background-size: cover;
        min-height: 45vh;
    }

    .contenido {
        max-width: 800px;
        left: 3%;
    }
}

@media (min-width: 2560px) and (max-width: 3839px) {
    .bgAirplaneSky {
        background-size: cover;
        min-height: 45vh;
    }

    .contenido {
        max-width: 550px;
        left: 3%;
    }
}

@media (max-width: 1440px) and (min-width: 1025px) { 
    .bgAirplaneSky {
        background-size: cover;
        min-height: 20vh;
    }

    .contenido {
        max-width: 350px;
        left: -2%;
    }
}

@media screen and (max-width: 1024px) {

    .bgAirplaneSky {
        background-size: cover;
        min-height: 20vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .contenido {
        max-width: 300px;
        position: relative;
        left: -20%;
        top: auto;
        transform: translate(-50%);
        text-align: center;
    }

    .segundaSeccion {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
    }

    .botones-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        max-width: none;
    }

    .botonGral {
        width: auto;
        max-width: 150px;
        text-align: center;
    }

    .terceraSeccion.active {
        margin-top: 110px;
    }

    .columnas-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
    }

    .columna1 {
        min-height: auto;
    }

    .columna2 {
        min-height: auto;
    }

    .columna1 h1{
        text-align: start;
    }

    .columna2 h1{
        text-align: start;
    }


    .cuartaSeccion {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 60px;
    }

    .scroll-top-button {
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 20px;
        z-index: 1000;
        display: block;
    }

    .scroll-top-button:hover {
        color: var(--color-mexican-pink);
    }
}

@media screen and (max-width: 920px) {
    .contenido {
        max-width: 300px;
        position: relative;
        left: -14%;
        top: auto;
        transform: translate(-50%);
        text-align: center;
    }
}

@media screen and (max-width: 820px) {
    .contenido {
        max-width: 250px;
        position: relative;
        left: -15%;
        top: auto;
        transform: translate(-50%);
        text-align: center;
    }
    
}

@media screen and (max-width: 768px) {
    .primeraSeccion {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .bgAirplaneSky {
        background-size: cover;
        background-position: center;
        min-height: 20vh;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    .contenido {
        max-width: 215px;
        position: absolute;
        left: 15%;
        top: auto;
        transform: translate(-50%);
        text-align: center;
    }

    .columna1 {
        min-height: auto;
    }

    .columna2 {
        min-height: auto;
    }

    .botones-container {
        max-width: none;
    }

    .scroll-top-button {
        margin-top: 30px;
    }
    
}

@media screen and (min-width: 769px) and (max-width: 1024) {
    .contenido {
        max-width: 280px;
        left: 3%;
    }
}

@media screen and (max-width: 560px) {
    .contenido {
        max-width: 190px;
        position: absolute;
        left: 18%;
        top: auto;
        transform: translate(-50%);
        text-align: center;
    }
}

@media screen and (max-width: 480px) {
    .bgAirplaneSky {
        background-size: cover;
        background-position: center;
        min-height: 15vh;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .columnas-container {
        margin-top: 30px;
    }

    .contenido {
        max-width: 150px;
        left: 17%;
        top: auto;
    }

    .columna1 {
        min-height: 0px;
    }

    .columna2 {
        min-height: auto;
    }

    .iconoRight {
        display: none;
    }

    .botonGral {
        width: 90%;
        max-width: none;
    }
}

@media screen and (max-width: 370px) {
    .contenido {
        max-width: 150px;
        left: 17%;
        top: auto;
    }

    .botones-container {
        flex-direction: column;
    }

    .botonGral {
        width: 90%;
        max-width: none;
    }
    
}

@media screen and (max-width: 320px) {
    .bgAirplaneSky {
        background-size: cover;
        background-position: center;
        padding: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 25vh;
    }

    .terceraSeccion.active {
        margin-top: 80px;
    }

    .contenido {
        max-width: 270px;
        position: absolute;
        left: 50%;
        top: 65%;
        transform: translate(-50%);
        text-align: center;
    }

    .columna1 {
        margin-top: 5px;
        min-height: 0px;
    }

    .columna2 {
        min-height: auto;
    }

    .segundaSeccion {
        margin-top: 50px;
    }

    .botones-container {
        flex-direction: column;
        margin-top: 50px;
    }

    .botonGral {
        width: 90%;
        max-width: none;
    }
}