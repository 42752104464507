.bg-landing {
  background: #ececec;
  background-image: url(/src/assets/imgs/landing-page/aeromexico_new_cover.jpg);
  background-size: 130%;
  background-origin: border-box;
  background-repeat: no-repeat; 
  background-position: 0% 50%;
  height: 55vh;
  position: relative; 
  z-index: 1;
}

/* Ocultar en desktop y tablets el diseño mobile de banners */
.banner-slider-mobile {
  display: none;
}

.carousel-item-container {
  height: 55vh;
}

@media (max-width: 1428px) and (min-width: 991px) {
  .carousel-item-container .col-lg-8 {
    max-width: 55%; /* Reduce el ancho del banner */
  }
}

.carousel-caption {
  z-index: 2;
  position: relative;
  pointer-events: none; /* Evita que interfiera con los indicadores */
}

.carousel-indicators button {
  border-radius: 50%;
  width: 10px;
  max-width: 10px;
  height: 10px;
  min-height: 10px;
  background: #fff;
  z-index: 3;
}

.carousel-indicators button.active {
  background-color: var(--color-azul-innovacion); 
}

/* .banner-slider-desktop .banner-container-desktop {

} */

.banner-slider-desktop .banner-container-desktop .banner-img-container {
  margin-top: 2rem;
}

.banner-slider-desktop .banner-container-desktop .banner-img-container img {
  width: 100%;
}

.banner-slider-desktop .banner-container-desktop .btn-banner-desktop {
  position: absolute;
  left: 1.8rem;
  bottom: 20px;
}

/* Ajuste de boton para iPad pro */
@media (max-width: 1160px) and (min-width: 992px) {
  .banner-slider-desktop .banner-container-desktop .btn-pink-inverted {
    font-size: 0.5rem;
  }
  
  .banner-slider-desktop .banner-container-desktop .btn-banner-desktop {
    left: 1.2rem;
    bottom: 8px;
  }
}

.bg-premium-blue{
  background-color: #020C41;
}

.btn-pink {
  border-radius: 6px;
  background-color: var(--color-azul-innovacion);
  border: none;
  padding: 10px 15px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: .3s ease all;
  pointer-events: auto; /* Reactiva los eventos para el botón */
}

.btn-pink:hover {
  background: var(--color-mexican-pink);
  color: #fff;  
}

.btn-pink-inverted {
  border-radius: 6px;  
  background: var(--color-mexican-pink);
  border: none;
  padding: 10px 15px;
  color: #fff;
  font-size: 0.9rem;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: .3s ease all;
  pointer-events: auto; /* Reactiva los eventos para el botón */
  margin-left: 0.5rem;
}

.btn-pink-inverted:hover {
  background-color: var(--color-azul-innovacion);
  color: #fff;  
}

.img-services{
  border-radius: 5px;
  width: 280px;
  height: auto;
}

.img-slider{
  margin: 0px 80px;
}

/* SERVICIOS */

.services {
  background-color: #D4D4D4;
}

.service-card .title {
  font-size: 24px;
}

@media (max-width: 1186px) and (min-width: 767px) {
  .service-card .title {
    font-size: 0.95rem;
  }

  .service-card .service-description {
    font-size: 14px;
  }
}

.service-card:hover{
  background-color: #020C41;
  transition: background-color 0.5s, color 0.5s;
}

.service-card:hover p{
  color: white !important;
}
/* SERVICIOS */

/* SECIONES OCULTAS (Shipping Process y Contact) */
.hidden-sections {
  display: none;
}

.text-transparent{
  color:transparent !important;
}

.land-z-1{
  position: relative;
  z-index: 1;
}

.landing-banner-img{
  z-index: 0;
}

@media (max-width: 930px) and (min-width: 700px) {
  .bg-landing {
    background-size: 150%;
    height: 35vh;
  }

  .carousel-item-container {
    height: 35vh;
  }

  .btn-pink-inverted {
    padding: 5px 15px;
    margin-left: 0rem;
    margin-top: 1rem;
  }
}

@media (max-width: 700px) {
  .banner-slider-desktop {
      display: none;
  }

  .banner-slider-mobile {
    display: block;
  }

  .banner-slider-mobile .mobile-banner-carousel {
    padding: 20px 20px 0px 20px;
  }

  .banner-slider-mobile .mobile-fixed-image {
    padding: 10px 20px 20px 20px;
  }

  .banner-slider-mobile .mobile-fixed-image img {
    width: 100%;
    height: auto;
    display: block;
  }

  .banner-slider-mobile .mobile-banner-carousel .mobile-banner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  
  .banner-slider-mobile .mobile-banner-carousel .mobile-banner-img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .banner-slider-mobile .mobile-banner .mobile-banner-button {
    position: absolute;
    bottom: 5%;
    left: 5%;
    transform: translateY(-60%);
  }
}