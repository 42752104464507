.container-form-info {
    background-color: #f4f4f4;
    margin: 0;
    padding: 0;
}

.container-form-info .row .col {
    max-width: 600px;
    margin: 50px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: rgb(2, 12, 65); /* Azul Premium */
  font-size: 32px;
  margin-bottom: 20px;
}

label {
  font-size: 16px;
  color: rgb(2, 12, 65); /* Azul Premium */
  margin-bottom: 5px;
  display: block;
}

.contact-form input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.contact-form input[type="text"]:focus {
  border-color: rgb(2, 12, 65); /* Azul Premium */
}

.contact-form input[type="submit"] {
  background-color: rgb(250, 0, 115); /* Rosa Mexicano */
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.contact-form input[type="submit"]:hover {
  background-color: rgb(200, 0, 95); /* Rosa Mexicano oscuro */
}