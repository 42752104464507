/***FOOTER***/
.footer{
	background-color: var(--color-blue-hard);
	color: white;
	padding-top: 6em;
	padding-bottom: 4em;
}

.footer_container{
	width: 100%;
  	position: relative;
}

.footer__contact__phone{
	width: 100%;
	position: relative;
}

.footer__contact__phone h4 {
	font-family: NeueHaasUnicaPro-Medium,sans-serif;
	color: white;
	font-size: 24px;
	line-height: 1.5;
	position: relative;
	margin-bottom: 0px;
}

.footer__contact__buttons {
	width: 100%;
	position: relative;
	margin-top: 32px;
}

.footer__contact__buttons_a {
	font-family: NeueHaasUnicaPro-Medium,sans-serif;
	font-weight: 600;
	font-size: 12px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.ctm-button {
  	font-family: NeueHaasUnicaPro-Medium,sans-serif !important;
  	font-weight: 600 !important;
  	color: white !important;
  	text-transform: uppercase !important;
  	border-radius: 34px !important;
  	padding-top: 10px !important;
  	padding-bottom: 10px !important;
  	background-color: var(--color-azul-innovacion) !important;
  	border:0 !important;
  	width: 100% !important;
}
.ctm-button:hover {
	background-color: var(--color-mexican-pink) !important;
}

.footer__contact__buttons_a::before {
	content: "";
	width: 0%;
	height: 1px;
	left: 0px;
	bottom: -4px;
	right: inherit;
	background-color: white;
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.footer__contact__buttons_a {
	color: white;
	font-family: NeueHaasUnicaPro-Medium,sans-serif;
	font-weight: 600;
	font-size: 20px;
	letter-spacing: 1px;
}

.btn {
  	border: 1px solid transparent;
  	text-decoration: none;
}

.footer__contact__buttons_a:hover {
  	-webkit-transition: all 0.3s ease-in-out;
  	transition: all 0.3s ease-in-out;
  	background-color: var(--color-red);
  	color:white;
  	text-decoration: none;
}

.footer__contact__phone_p {
    color: rgba(255,255,255,0.6);
    font-size: 15px;
    padding: 0px;
    margin-top: 12px;
    margin-bottom: 0px;
    position: relative;
}

.copy-footer{
	margin-top: 35px !important;
	padding-top: 22px;
	border-top: 1px solid rgba(255,255,255,0.2);
}

.servicios-footer{
	font-size: 16px;
	margin-top: 35px;
	/* font-weight: 100; */
	text-decoration: none;
	color: #fff;
	cursor: pointer;
}

.servicios-footer:hover{
	font-size: 16px;
	color: var(--color-mexican-pink);
	/* font-weight: 100; */
}

.servicios-icon{
	height: 32px !important;
}

.servicios-icon2{
	height: 22px !important;
}

.c-pointer{
	cursor: pointer;
}

.middle-column{
	border-left: 1px solid rgba(255,255,255,0.2);
}

.footer__section__services {
  width: 75%;
  margin: 0 auto;
  position: relative;
}

.footer__information {
  width: 75%;
  margin: 0 auto;
  position: relative;
}

.left-column{
	border-left: 1px solid rgba(255,255,255,0.2);
}
/***Fonts***/
.no-a{
	text-decoration: none;
}
.no-a-footer{
	text-decoration: none;
	color: white;
}

.no-a-footer:hover{
	text-decoration: none;
	color: white;
}

.fs-18{
	font-size: 23px !important;
}

.fs-12{
	font-size: 12px !important;
}

.text-end-normal{
	position: absolute;
	left: 90%;
	background-color: transparent !important;
}

.fs-9{
	font-size: 13px;
}

#btnFooterHome {
	width: 100%;
	display: flex;
}

#btnFooterHome img {
	width: 100%;
}


@media (min-width: 1601px) {
  	.footer__section__services_h5 {
    	font-size: 20px;
  	}
}

@media (min-width: 1367px) and (max-width: 1600px) {
  	.footer__contact__phone_p {
    	font-size: 15px;
  	}

  	.footer__section__services_h5 {
    	font-size: 20px;   
  	}
}

@media (max-width: 992px) {
  	.middle-column {
    	border-left: 1px solid transparent;
  	}
}

@media (max-width: 768px){
  	.left-column{
		border-left: 1px solid transparent;
		border-top: 1px solid rgba(255,255,255,0.2);
	}

	.middle-column{
		border-left: 1px solid transparent;
		border-top: 1px solid rgba(255,255,255,0.2);
	}

	.ctm-button{
		font-size: 15px !important;
	}

	.footer__section__services_h5 {
    	font-size: 18px;  
    	margin-top: 1rem; 
  	}

	.footer__section__services {
	  width: 100%;
	  margin: 0 auto;
	  position: relative;
	}

	.footer__information {
	  width: 100%;
	  margin: 0 auto;
	  position: relative;
	}

	.servicios-footer{
		font-size: 16px;
		margin-top: 20px;
		font-weight: 100;
	}

	.copy-footer{
		border: 1px solid transparent;
	}
}

