.collection-inpector {
  width: 100%;
  height: 100vh;
}

.collection-inpector-body {
  width: 100%;
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.return-container {
  width: 5%;
  height: 8%;
  position: absolute;
  top: 22%;
  left: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.return-btn {
  width: 70%;
  height: 60%;
  align-items: center;
  text-align: center;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  background-color: #020b41;
}

.arrow-btn {
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}

.collection-inpector-body h4 {
  font-size: 1.8rem;
  margin-bottom: 40px;
}

.collection-inpector-body .table-container {
  width: 100%;
  height: 27rem;
  max-height: 27rem;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  scrollbar-width: none;
}

.collection-inpector-body .table-container::-webkit-scrollbar {
  display: none;
}

.collection-inpector-body table {
  width: 100%;
  max-width: 1500px;

  border-collapse: separate;
  border-spacing: 10px 5px;
  margin-top: 1rem;
}

.collection-inpector-body table thead {
  background-color: #a3a3a3;
  color: white;
}

.collection-inpector-body table thead th {
  padding: 0.75rem;
  text-align: center;
  font-weight: bold;
}

.collection-inpector-body table tbody td {
  padding: 0.75rem;
  border: 1px solid black;
  text-align: center;
}

.collection-inpector-body table tbody td:first-child {
  background: var(--color-premium-blue);
  color: #fff;
}

.collection-inpector-body table .status {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  width: 179px;
}

.collection-inpector-body table .status.searching {
  background-color: #035cf7;
  color: #fff;
}

.collection-inpector-body table .status.found {
  background-color: #66c87b;
  color: #fff;
}

.collection-inpector-body table .status.found-partial {
  background-color: #f27d38;
  color: #fff;
}
.collection-inpector-body table .status.not-found {
  background-color: #ed544e;
  color: #fff;
}
.collection-inpector-body table .status.accepted {
  background-color: #66c87b;
  color: #fff;
}
.collection-inpector-body table .status.not-accepted {
  background-color: #ed544e;
  color: #fff;
}
.collection-inpector-body table .status.null {
  color: #000000;
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
    TABLETS
    ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .return-container {
    width: 8%;
    height: 5%;
  }
}

/* ============================================================
        PHONE
        ============================================================ */
@media screen and (max-width: 620px) {
  .collection-inpector-body {
    width: 100%;
    height: 82%;
  }

  .return-container {
    width: 15%;
    height: 6%;
    top: 147px;
  }
}
