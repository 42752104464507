.reception-container {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}
/* Banner */
.reception-container .reception-banner {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
}

.reception-banner .banner-header {
  background-color: #020c41;
  display: flex;
  flex-direction: column;
  height: 20%;
  width: 100%;
}

.banner-header .header-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 100%;
}

.header-image img {
  width: 300px;
  height: 300px;
}

.banner-header .header-hours {
  background-color: #030d75;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 30%;
  width: 100%;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

.reception-banner .banner-body {
  height: 80%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.banner-body .banner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  z-index: 1;
  object-fit: cover;
}

/* turns */
.reception-container .reception-turns {
  width: 40%;
  height: 100%;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.reception-turns .turns-body {
  background-color: #f9fafc;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}

.turns-body .next-shifts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  height: 80%;
}

.next-shifts h1 {
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  color: #737373;
}

.tabla {
  display: flex;
  flex-direction: column;
  border: none;
  background-color: transparent;
  width: 85%;
  gap: 10px;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

.tabla-header {
  display: flex;
  gap: 0px;
  background-color: transparent;
  font-weight: bold;
}

.header-cell {
  flex: 1;
  padding: 10px;
  text-align: center;
  background-color: #020c41;
  border-radius: 0px;
  color: #ffffff;
}

.tabla-row {
  display: flex;
  gap: 10px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

.cell {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ccc;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 0px;
}

.cell.current-turn {
  background-color: #035cf7 !important;
  color: #ffffff !important;
}

.cell.next-turn {
  background-color: #ffffff !important;
  border: #262626 1px solid !important;
  color: #171717 !important;
}

.cell.last-turn {
  background-color: #a3a3a3 !important;
  border: none !important;
  color: #ffffff !important;
}

.cell.dark {
  background-color: #00174d;
  color: #fff;
}

.turns-body-messages {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #f9fafc;
}

.turns-body-messages h1 {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
}

@media screen and (min-width: 1281px) {
}

@media screen and (min-width: 913px) and (max-width: 1280px) {
}

/* ============================================================
  TABLETS
  ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .reception-container .reception-banner {
    display: none;
  }

  .reception-container .reception-turns {
    width: 100%;
    height: 100%;
  }
}

/* ============================================================
      PHONE
      ============================================================ */
@media screen and (max-width: 620px) {
  .reception-container .reception-banner {
    display: none;
  }

  .reception-container .reception-turns {
    width: 100%;
    height: 100%;
  }
}
