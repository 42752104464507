.weigher-container {
  width: 100%;
  height: 100vh;
}

.weigher-body {
  height: 78%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.weigher-body .weigher-body-title {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
}

.weigher-body .weigher-body-title h1 {
  font-style: 15px;
  text-align: center;
}

.weigher-body .weigher-body-turns {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.weigher-body .weigher-body-turns-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 4px;
  padding: 2px;
  width: 70%;
  height: 50%;
}

/* Cards */
.turn-card {
  background-color: #001244;
  color: #ffffff;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.turn-card:hover {
  background-color: rgba(0, 18, 68, 0.8);
}

.turn-card h1 {
  font-size: 25px;
}

.turn-card h3 {
  font-size: 20px;
}

.turn-card p {
  font-size: 16px;
}

.loading-spinner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Modal */
.custom-modal-width-weigher {
  max-width: 900px !important;
}

.accept-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 5px;
}

.accept-container .btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.accept-container .justification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
}

.accept-container .justification h6 {
  color: #020c41;
}

.justification .formGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0 10px 0;
  width: 100%;
}

.justification .formGroup textarea {
  height: 80px;
  min-height: 80px;
  padding: 15px 10px 10px 10px;
  border: #d4d4d4 solid 1px;
  color: #000000;
}

.justification .formGroup textarea:disabled {
  min-height: 80px;
  max-height: 80px;
}

.justification .formGroup textarea:disabled + label {
  opacity: 0.3;
  cursor: auto;
}

.justification .formGroup textarea:focus + label,
.justification .formGroup textarea:not(:placeholder-shown) + label {
  transform: translateY(1px) scale(0.6);
  transform-origin: left top;
  color: #020c41;
}

.justification .formGroup label {
  cursor: pointer;
  position: absolute;
  top: 2px;
  transform: translateY(10px);
  transition: transform 0.5s #000 0.3s;
  transition: 0.3s ease all;
  font-size: 1rem;
  margin-bottom: 4px;
  color: #000;
}

.justification .formGroup .show-pass.disabled {
  color: #c6c6c6;
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 1280px) and (max-width: 1919px) {
}

@media screen and (min-width: 913px) and (max-width: 1279px) {
}

/* ============================================================
      TABLETS
      ============================================================ */
@media screen and (min-width: 601px) and (max-width: 912px) {
  .weigher-body .weigher-body-turns-cards {
    width: 95%;
    height: 90%;
  }
}

/* ============================================================
          PHONE
          ============================================================ */
@media screen and (max-width: 620px) {
  .weigher-body .weigher-body-title {
    margin-top: 2rem;
  }

  .weigher-body {
    height: 78%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .weigher-body .weigher-body-title h1 {
    font-style: 15px;
    text-align: center;
  }

  .weigher-body .weigher-body-turns-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    gap: 4px;
    padding: 2px;
    width: 95%;
    height: 100%;
  }
}
